<template>
  <va-content class="typography content">
    <div>
      <va-card class="col-md-12">
        <va-card-content>
          <div class="mb-4">
            <h4>PISUCON とは</h4>
            <p>
              <a href="https://trap.jp" target="_blank">東京工業大学デジタル創作同好会traP</a> の部内ISUCON のことです.
            </p>
          </div>
          <div class="mb-4">
            <h4>ISUCONとは</h4>
            <p>
              Iikanjini SpeedUp CONtest の略で, サーバーをチューニングして高いスコアを目指す大会です.
              <br />毎年夏に開催されています.<br />
              <a href="http://isucon.net/" target="_blank">ISUCON 公式サイト</a>
            </p>
          </div>
          <div class="mb-4">
            <h4>運営からのお願い</h4>
            <div>
              <li>悪いことはしないでね</li>
              <li>バグとかあったらこっそり教えてください</li>
            </div>
          </div>
        </va-card-content>
      </va-card>
    </div>
  </va-content>
</template>

<script>
export default {
  name: 'readme'
}
</script>

<style lang="sass" scoped>
.box
  margin-bottom: 48px

a
  color: #4ae287

.h-fix
  margin-bottom: auto
  margin-top: auto
</style>

